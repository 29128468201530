import SignIn from 'components/auth/SignIn'
import { withRouter } from 'next/router'
import Head from 'next/head'
import Link from 'next/link'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import Layout from 'components/Layout'

export default withRouter(class Page extends React.Component {
  render() {
    let router = this.props.router

    let signInMessage = router.query.next ? (
      <Alert variant='warning' className="text-center">Please sign in to continue.</Alert>
    ) : undefined

    return (
      <>
        <Head>
          <title>Sign In | Value Investor Studio</title>
        </Head>
        <Layout showNav={false} hideFooter={true} className="bg-light">
          <Container fluid>
            <Row>
              <Col className="d-none d-md-block bg-white">
                <Row className="align-items-center justify-content-center" style={{
                  minHeight: '100vh'
                }}>
                  <Col className="my-3" style={{ maxWidth: '30em' }}>
                    <h1 className="text-center font-weight-bold">Value Investor Studio</h1>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row className="align-items-center justify-content-center" style={{
                  minHeight: '100vh'
                }}>
                  <Col className="my-3" style={{ maxWidth: '30em', width: '100%' }}>
                    {signInMessage}
                    <SignIn/>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Layout>
      </>
    )
  }
})
