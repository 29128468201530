import {
  useEffect,
  useContext,
  useState
} from 'react'
import getConfig from 'next/config'
import * as Sentry from '@sentry/browser'
import Head from 'next/head'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { authService, userService } from 'value-studio-sdk'
import AppContext from 'components/AppContext'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import AppleSvg from 'components/auth/icons/apple.svg'
import GoogleSvg from 'components/auth/icons/google.svg'



const { serverRuntimeConfig, publicRuntimeConfig } = getConfig()
const isProd = publicRuntimeConfig.NODE_ENV == 'production'

const SignIn = () => {
  const router = useRouter()
  let emailInput = null

  let [isLoading, setIsLoading] = useState(false)
  let [email, setEmail] = useState()
  let [passphrase, setPassphrase] = useState()
  let [authError, setAuthError] = useState()

  let context = useContext(AppContext)

  useEffect(() => {
    emailInput.focus()
  }, [])

  let handleDidAuthenticate = () => {
    userService.getProfile().then(user => {
      let next = router.query.next
      if (next) return router.push(next)
      router.push('/')
    })
  }

  let handleFormSubmit = (e) => {
    e.preventDefault()
    setIsLoading(true)
    authService.signInWithEmail(email, passphrase).then(data => {
      handleDidAuthenticate()
    }).catch(err => {
      setIsLoading(false)

      if (err.response && err.response.data) return setAuthError(err.response.data.message)
      if (isProd) Sentry.captureException(err)

      return setAuthError('Sign in failed. Please try again later.')
    })
  }

  let handleEmailChange = (e) => {
    e.preventDefault()
    setEmail(e.target.value)
  }

  let handlePassphraseChange = (e) => {
    e.preventDefault()
    setPassphrase(e.target.value)
  }

  let handleClickSignInWithApple = (e) => {
    e.preventDefault()
    setIsLoading(true)

    AppleID.auth.init({
      clientId : 'com.valuestudio.web',
      scope : 'name email',
      redirectURI : 'https://valueinvestorstudio.com/auth-callback',
      state : 'bobsburglers',
      usePopup : true //or false defaults to false
    })

    AppleID.auth.signIn().then(data => {
      let idToken = data.authorization.id_token
      let userInfo = data.user

      return authService.signInWithApple(idToken, userInfo)
    }).then(res => {
      debugger
      setIsLoading(false)
      handleDidAuthenticate()
    }).catch(error => {
      console.log('error:', error)
      if (isProd) Sentry.captureException(error);
      setIsLoading(false)
      debugger
    })
  }

  let handleClickSignInWithGoogle = (e) => {
    let clientId = '323195901860-kj37v4a4j2uhumh4e7u6j9vddr1op9ur'
    setIsLoading(true)

    gapi.load('auth2', () => {
      gapi.auth2.init({
        client_id: `${clientId}.apps.googleusercontent.com`,
        scope: 'email profile openid',
        // response_type: 'id_token permission'
        response_type: 'code'
      }).then((googleAuth) => {
        return googleAuth.signIn()
      }).then(googleUser => {
        let idToken = googleUser.getAuthResponse().id_token
        return authService.signInWithGoogle(idToken)
      }).then(data => {
        handleDidAuthenticate()
      }).catch(err => {
        if (isProd) Sentry.captureException(err);
        setIsLoading(false)
        debugger
      })
    }, (err) => {
      setIsLoading(false)
      debugger
    })
  }

  let authAlert = () => {
    if (!authError) return undefined;
    return (
      <Alert variant='danger'>{authError}</Alert>
    )
  }

  return (
    <>
      <Head>
        <script
          type="text/javascript"
          src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js">
        </script>
        <script src="https://apis.google.com/js/platform.js" async defer></script>
      </Head>
      <h4 className="text-center my-3 text-muted">Welcome</h4>
      <div style={{width: '100%', maxWidth: '30em'}}>
        {authAlert()}
        <div className="my-4">
          <Button
            block
            size="lg"
            variant="secondary"
            className="rounded-pill btn-flex"
            onClick={handleClickSignInWithApple}
          >
            <AppleSvg className="mr-2" style={{ marginTop: '-2px' }} fill="currentColor" />
            Sign in with Apple
          </Button>
          <Button
            block
            size="lg"
            variant="secondary"
            className="rounded-pill btn-flex"
            onClick={handleClickSignInWithGoogle}
          >
            <GoogleSvg className="mr-2" fill="currentColor" />
            Sign in with Google
          </Button>
        </div>

        <hr className="my-4" />

        <Form onSubmit={handleFormSubmit}>
          <Form.Group>
            <div className="form-label-group">
              <Form.Control ref={(input) => { emailInput = input; }} id="email-input" onChange={handleEmailChange} type="text" placeholder="Email"/>
              <Form.Label htmlFor="email-input">Email Address</Form.Label>
            </div>
          </Form.Group>
          <Form.Group>
            <div className="form-label-group">
              <Form.Control id="passphrase-input" onChange={handlePassphraseChange} type="password" placeholder="Passphrase"/>
              <Form.Label htmlFor="passphrase-input">Passphrase</Form.Label>
            </div>
          </Form.Group>
          <Button type="submit" variant="primary" size="lg" disabled={isLoading} block>{isLoading ? 'Signing In...' : 'Sign In'}</Button>
          <Link href="/sign-up">
            <Button as="a" variant="link" block>or Create an account</Button>
          </Link>
        </Form>
      </div>
    </>
  )
}


export default SignIn
